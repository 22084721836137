import {
  ref, getCurrentInstance, reactive, onMounted, computed
} from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { comissionUseCase } from '@/domain/usecase'

export default {
  props: {
    dataEdit: {
      default: {}
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit } :any) {
    const app = getCurrentInstance()
    const { $toast, $confirm } = app!.appContext.config.globalProperties
    const { dataEdit } = reactive(props)
    const title = ref('Tambah Komisi')
    const isActive = ref(true)
    const submitted = ref(false)
    const store = useStore()
    const dataForm = reactive({
      name: '',
    })
    const defaultDetailInput = () => [
      {
        KomisiAgen: 0,
        KomisiKru: 0,
        KomisiPerusahaan: 0,
        TipePengiriman: 'AA'
      },
      {
        KomisiAgen: 0,
        KomisiKru: 0,
        KomisiPerusahaan: 0,
        TipePengiriman: 'DD'
      },
      {
        KomisiAgen: 0,
        KomisiKru: 0,
        KomisiPerusahaan: 0,
        TipePengiriman: 'DA'
      },
      {
        KomisiAgen: 0,
        KomisiKru: 0,
        KomisiPerusahaan: 0,
        TipePengiriman: 'AD'
      }
    ]
    const detailInput = ref(defaultDetailInput())

    const rules = {
      name: {
        required: helpers.withMessage('Nama komisi harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const submitProccess = async () => {
      store.dispatch('showLoading')
      const dataSubmit = {
        Nama: dataForm.name,
        Version: 1,
        IsActive: isActive.value,
        MsKomisiDetails: detailInput.value
      }
      const id = Object.keys(dataEdit).length > 0 ? dataEdit.Id : ''
      const response = await comissionUseCase.submitData(id, dataSubmit)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        closeSidebar()
        emit('reloadData')
      }
      console.log('submitData', dataSubmit)
      store.dispatch('hideLoading')
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }
    const deleteData = (evt: any) => {
      $confirm.require({
        header: 'Komisi',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await comissionUseCase.deleteData(dataEdit.Id)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 1500
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: 'Behasil dihapus', group: 'bc', life: 1500
            })
            closeSidebar()
            emit('reloadData')
          }
        }
      })
    }
    const generateEditData = () => {
      if (Object.keys(dataEdit).length > 0) {
        dataForm.name = dataEdit.Nama
        detailInput.value = dataEdit.MsKomisiDetails
        isActive.value = dataEdit.IsActive
      } else {
        dataForm.name = ''
        detailInput.value = defaultDetailInput()
      }
    }

    onMounted(() => {
      generateEditData()
    })
    return {
      v$,
      dataForm,
      submitted,
      isActive,
      detailInput,
      dataEdit,
      title,
      submitData,
      closeSidebar,
      deleteData
    }
  }
}
