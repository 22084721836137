<template>
<section class="comission-form relative h-full px-3 overflow-y-auto overflow-x-hidden">
  <div class="title-form py-5 mb-6 flex items-center justify-between" style="border-bottom: 1px solid #CFD8DC">
    <h6 class="font-bold">{{ title }}</h6>
    <!-- <div class="background-red-lighten-5 p-3 rounded-md cursor-pointer" @click="deleteData($event)" v-if="Object.keys(dataEdit).length > 0">
      <img src="@/assets/img/icon/deleteFilledAntDesignRed.svg" class="w-3 h-3" alt="delete">
    </div> -->
  </div>
  <form @submit.prevent="submitData(!v$.$invalid)">
    <div class="p-fluid">
      <div class="p-field mb-4">
        <label for="name" :class="{'p-error':v$.name.$invalid && submitted }">Nama Komisi</label>
        <InputText id="name" class="input-primary mt-1" type="text" name="name" v-model="v$.name.$model" />
        <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{ v$.name.required.$message }}</small>
      </div>
      <div class="w-full mb-4" v-for="(item, index) in detailInput" :key="'group' + index">
        <p class="mb-1">{{ item.TipePengiriman.toUpperCase() }}</p>
        <div class="grid-cols-3 gap-3 grid">
          <div class="p-field">
            <label class="color-blue-grey">Agent</label>
            <div class="p-inputgroup rounded-lg mt-1">
              <InputText type="number" v-model="item.KomisiAgen" min="0" />
              <span class="p-inputgroup-addon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                  <path d="M9.5 6A3.514 3.514 0 0 0 6 9.5v3C6 14.422 7.578 16 9.5 16s3.5-1.578 3.5-3.5v-3C13 7.578 11.422 6 9.5 6zm11.25 0l-12 20h2.5l12-20zM9.5 8c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5S8 13.34 8 12.5v-3C8 8.66 8.66 8 9.5 8zm13 8a3.514 3.514 0 0 0-3.5 3.5v3c0 1.922 1.578 3.5 3.5 3.5s3.5-1.578 3.5-3.5v-3c0-1.922-1.578-3.5-3.5-3.5zm0 2c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5s-1.5-.66-1.5-1.5v-3c0-.84.66-1.5 1.5-1.5z" fill="#607D8B" /></svg>
              </span>
            </div>
          </div>
          <div class="p-field">
            <label class="color-blue-grey">Kru</label>
            <div class="p-inputgroup rounded-lg mt-1">
              <InputText type="number" v-model="item.KomisiKru" min="0" />
              <span class="p-inputgroup-addon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                  <path d="M9.5 6A3.514 3.514 0 0 0 6 9.5v3C6 14.422 7.578 16 9.5 16s3.5-1.578 3.5-3.5v-3C13 7.578 11.422 6 9.5 6zm11.25 0l-12 20h2.5l12-20zM9.5 8c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5S8 13.34 8 12.5v-3C8 8.66 8.66 8 9.5 8zm13 8a3.514 3.514 0 0 0-3.5 3.5v3c0 1.922 1.578 3.5 3.5 3.5s3.5-1.578 3.5-3.5v-3c0-1.922-1.578-3.5-3.5-3.5zm0 2c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5s-1.5-.66-1.5-1.5v-3c0-.84.66-1.5 1.5-1.5z" fill="#607D8B" /></svg>
              </span>
            </div>
          </div>
          <div class="p-field">
            <label class="color-blue-grey">Perusahaan</label>
            <div class="p-inputgroup rounded-lg mt-1">
              <InputText type="number" v-model="item.KomisiPerusahaan" min="0" />
              <span class="p-inputgroup-addon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                  <path d="M9.5 6A3.514 3.514 0 0 0 6 9.5v3C6 14.422 7.578 16 9.5 16s3.5-1.578 3.5-3.5v-3C13 7.578 11.422 6 9.5 6zm11.25 0l-12 20h2.5l12-20zM9.5 8c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5S8 13.34 8 12.5v-3C8 8.66 8.66 8 9.5 8zm13 8a3.514 3.514 0 0 0-3.5 3.5v3c0 1.922 1.578 3.5 3.5 3.5s3.5-1.578 3.5-3.5v-3c0-1.922-1.578-3.5-3.5-3.5zm0 2c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5s-1.5-.66-1.5-1.5v-3c0-.84.66-1.5 1.5-1.5z" fill="#607D8B" /></svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="p-field" v-if="Object.keys(dataEdit).length > 0">
        <label for="idActive">Aktif</label>
        <div>
          <InputSwitch id="idActive" class="switch-og mt-1" v-model="isActive" />
        </div>
      </div>
    </div>
    <div class="btn-action w-full mt-auto mb-5">
      <Button label="Simpan" class="font-semibold p-button-sm btn-primary px-10 mr-2" type="submit" />
      <Button class="btn-secondary text-black p-button-sm px-10 py-1" @click="closeSidebar()">Kembali</Button>
    </div>
  </form>
</section>
</template>

<script src="@/composables/master/transactions/comission/comissionFormComposable.ts"></script>
