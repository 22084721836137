import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { comissionUseCase } from '@/domain/usecase'
import deliveryType from '@/data/source/local/json/deliveryType.json'
import ComissionForm from '@/views/master/transactions/comission/ComissionForm.vue'

export default {
  components: {
    ComissionForm
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast, $numbers, $mapAll, $confirm
    } = app!.appContext.config.globalProperties
    const data = ref([]) as any
    const route = useRoute()
    const store = useStore()
    const op = ref()
    const expandedRowGroups = ref([]) as any
    const inputSearch = ref('')
    const dataEdit = ref({})
    const paginate = reactive({
      totalRecords: 0,
      skip: 0,
      top: $numbers.totalItemPerPage
    }) as any
    const filters = reactive({
      search: '',
      select: 'Nama,Id,Version,IsActive,LastUpdate',
      expand: 'MsKomisiDetails($select=Id, IsActive, KomisiAgen, KomisiHdId, KomisiKru, KomisiPerusahaan, LastUpdate, TipePengiriman)'
    })
    const sliderActive = ref(false)

    const getDataById = (ex: any) => {
      dataEdit.value = {
        Id: ex.group.Id,
        Nama: ex.group.Nama,
        Version: ex.group.Version,
        IsActive: ex.group.IsActive,
        MsKomisiDetails: data.value.filter((fil: any) => fil.group.Id === ex.group.Id).map((map: any) => ({
          KomisiAgen: map.KomisiAgen,
          KomisiKru: map.KomisiKru,
          KomisiPerusahaan: map.KomisiPerusahaan,
          TipePengiriman: map.TipePengiriman
        }))
      }
    }

    const redirectAdd = () => {
      dataEdit.value = {}
      sliderActive.value = true
      // console.log('redirect add')
    }

    const redirectEdit = (edit: any) => {
      dataEdit.value = edit
      sliderActive.value = true
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const showDropDown = (evt: any, select: any) => {
      getDataById(select)
      // dataEdit.value = select
      op.value.toggle(evt)
    }

    const generateType = (arr: any) => {
      deliveryType.forEach((type: any) => {
        let hasType = false
        let exItem = {
          Id: 12,
          IsActive: false,
          KomisiAgen: 0,
          KomisiHdId: 0,
          KomisiKru: 0,
          KomisiPerusahaan: 0,
          LastUpdate: '2021-07-15T11:26:39.967+07:00',
          TipePengiriman: '',
        }
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item.TipePengiriman.toLowerCase() === type.toLowerCase()) {
            hasType = true
            exItem = item
            break
          }
        }
        if (!hasType) {
          exItem.TipePengiriman = type

          arr.push()
        }
      })
    }

    const checkColSpan = () => {
      const elem = document.getElementsByClassName('p-datatable-tbody')[0]
      const trElem = elem.getElementsByClassName('p-rowgroup-header') as any
      // eslint-disable-next-line no-restricted-syntax
      for (const tr of trElem) {
        const td = tr.getElementsByTagName('td')[0]
        td.colSpan = '4'
      }
    }

    const getAllData = async (reset = false) => {
      store.dispatch('showLoading')
      if (reset) {
        paginate.skip = 0
      }
      filters.search = inputSearch.value
      const response = await comissionUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        data.value = $mapAll(response.result.map((item: any) => item.MsKomisiDetails.map((com: any) => {
          com.group = {
            Id: item.Id,
            Nama: item.Nama,
            IsActive: item.IsActive,
            LastUpdate: item.LastUpdate,
            Version: item.Version,
            ShowAction: false
          }
          return com
        })))
        checkColSpan()
      }
      store.dispatch('hideLoading')
      // console.log(data.value)
    }

    const onPage = (evt: any) => {
      paginate.skip = evt.page * paginate.top
      getAllData()
    }

    const changeShowAction = (id: number, status: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].group.Id === id) {
          data.value[i].group.ShowAction = status
        }
      }
    }

    const onRowExpand = ($evt: any) => {
      // console.log('evt', $evt.data)
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].group.Id === $evt.data) {
          data.value[i].group.ShowAction = true
        }
      }
    }

    const onRowGroupCollapse = ($evt: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].group.Id === $evt.data) {
          data.value[i].group.ShowAction = false
        }
      }
    }

    const expandedGroup = (id: any) => {
      const check = expandedRowGroups.value.filter((val: number) => val === id).length

      if (check > 0) {
        expandedRowGroups.value = []
        changeShowAction(id, false)
      } else {
        if (expandedRowGroups.value.length > 0) {
          changeShowAction(expandedRowGroups.value[0], false)
        }
        changeShowAction(id, true)
        expandedRowGroups.value = [id]
      }
      // console.log('expandedGroup', id)
    }

    const deleteData = (id: any) => {
      $confirm.require({
        header: 'Komisi',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await comissionUseCase.deleteData(id.Id)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: response.message, group: 'bc', life: 1500
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: 'Behasil dihapus', group: 'bc', life: 1500
            })
            getAllData()
          }
        }
      })
    }

    const collapsedGroup = (id: any) => {
      expandedRowGroups.value = []
      changeShowAction(id, false)
    }

    onMounted(() => {
      getAllData()
    })

    return {
      op,
      data,
      route,
      inputSearch,
      paginate,
      expandedRowGroups,
      dataEdit,
      sliderActive,
      closeSidebar,
      collapsedGroup,
      expandedGroup,
      showDropDown,
      onRowExpand,
      onPage,
      deleteData,
      onRowGroupCollapse,
      redirectAdd,
      redirectEdit,
      getAllData
    }
  }
}