<template>
<section>
  <Card>
    <template #title>
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <h6 class="mr-3 font-normal">Komisi</h6>
          <Button v-if="$route.meta.permission.write" label="Tambah Komisi" @click="redirectAdd()" icon="pi pi-plus" class="font-semibold p-button-sm btn-primary" />
        </div>
        <div>
          <InputIconRightGrey placeholder="Cari Disini ..." v-model="inputSearch" :canClick="true" @submitInput="getAllData(true)" v-on:keyup.enter="getAllData(true)" :type="$enumBase.input.text" :icon="$icon.svg.searchEva" />
        </div>
      </div>
    </template>
    <template #content>
      <DataTable class="expanded-table table-og" :value="data" rowGroupMode="subheader" groupRowsBy="group.Id" sortMode="single" sortField="group.Id" :sortOrder="1" responsiveLayout="scroll" @rowgroupExpand="onRowExpand" @rowgroupCollapse="onRowGroupCollapse" :expandableRowGroups="true" v-model:expandedRowGroups="expandedRowGroups">
        <Column header="Nama/Tipe Pengiriman" >
          <template #body="slotProps">
            <p class="pl-12 pr-4 font-semibold">{{ slotProps.data.TipePengiriman }}</p>
          </template>
        </Column>
        <Column header="Agen" style="width:20%;text-align:center">
          <template #body="slotProps">
            <div class="p-inputgroup rounded-lg">
              <InputText id="KomisiAgen" class="input-primary mt-1" :disabled="true" name="KomisiAgen" v-model="slotProps.data.KomisiAgen" type="number" />
              <span class="p-inputgroup-addon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                  <path d="M9.5 6A3.514 3.514 0 0 0 6 9.5v3C6 14.422 7.578 16 9.5 16s3.5-1.578 3.5-3.5v-3C13 7.578 11.422 6 9.5 6zm11.25 0l-12 20h2.5l12-20zM9.5 8c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5S8 13.34 8 12.5v-3C8 8.66 8.66 8 9.5 8zm13 8a3.514 3.514 0 0 0-3.5 3.5v3c0 1.922 1.578 3.5 3.5 3.5s3.5-1.578 3.5-3.5v-3c0-1.922-1.578-3.5-3.5-3.5zm0 2c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5s-1.5-.66-1.5-1.5v-3c0-.84.66-1.5 1.5-1.5z" fill="#607D8B" /></svg>
              </span>
            </div>
          </template>
        </Column>
        <Column header="Kru" style="width:20%;text-align:center">
          <template #body="slotProps">
            <div class="p-inputgroup rounded-lg">
              <InputText id="KomisiKru" class="input-primary mt-1" :disabled="true" name="KomisiKru" v-model="slotProps.data.KomisiKru" type="number" />
              <span class="p-inputgroup-addon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                  <path d="M9.5 6A3.514 3.514 0 0 0 6 9.5v3C6 14.422 7.578 16 9.5 16s3.5-1.578 3.5-3.5v-3C13 7.578 11.422 6 9.5 6zm11.25 0l-12 20h2.5l12-20zM9.5 8c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5S8 13.34 8 12.5v-3C8 8.66 8.66 8 9.5 8zm13 8a3.514 3.514 0 0 0-3.5 3.5v3c0 1.922 1.578 3.5 3.5 3.5s3.5-1.578 3.5-3.5v-3c0-1.922-1.578-3.5-3.5-3.5zm0 2c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5s-1.5-.66-1.5-1.5v-3c0-.84.66-1.5 1.5-1.5z" fill="#607D8B" /></svg>
              </span>
            </div>
          </template>
        </Column>
        <Column header="Perusahaan" style="width:20%;text-align:center">
          <template #body="slotProps">
            <div class="p-inputgroup rounded-lg">
              <InputText id="KomisiPerusahaan" class="input-primary mt-1" :disabled="true" name="KomisiPerusahaan" v-model="slotProps.data.KomisiPerusahaan" type="number" />
              <span class="p-inputgroup-addon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                  <path d="M9.5 6A3.514 3.514 0 0 0 6 9.5v3C6 14.422 7.578 16 9.5 16s3.5-1.578 3.5-3.5v-3C13 7.578 11.422 6 9.5 6zm11.25 0l-12 20h2.5l12-20zM9.5 8c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5S8 13.34 8 12.5v-3C8 8.66 8.66 8 9.5 8zm13 8a3.514 3.514 0 0 0-3.5 3.5v3c0 1.922 1.578 3.5 3.5 3.5s3.5-1.578 3.5-3.5v-3c0-1.922-1.578-3.5-3.5-3.5zm0 2c.84 0 1.5.66 1.5 1.5v3c0 .84-.66 1.5-1.5 1.5s-1.5-.66-1.5-1.5v-3c0-.84.66-1.5 1.5-1.5z" fill="#607D8B" /></svg>
              </span>
            </div>
          </template>
        </Column>
        <template #groupheader="slotProps">

          <div class="action relative flex items-center justify-between w-full">
            <p class="pl-16 cursor-pointer font-semibold" @click="expandedGroup(slotProps.data.group.Id)">{{slotProps.data.group.Nama}}</p>
            <div v-if="route.meta.permission.update" class="absolute text-center flex  items-center " style="height: 100%; right: -25%; width:25%; border: 1 px solid #e9ecef; border-width: 0px 0px 1px 0px; padding: 1 rem 1 rem ;">
              <i class="pi pi-ellipsis-h text-base cursor-pointer text-black m-auto" aria:haspopup="true" @click="showDropDown($event, slotProps.data)"></i>
            </div>
          </div>
        </template>
      </DataTable>
      <Paginator :first="0" :rows="paginate.top" v-if="data.length > 0" :totalRecords="paginate.totalRecords" @page="onPage"></Paginator>
    </template>
  </Card>
  <OverlayPanel ref="op">
    <ListCopyEditDel :del="false" :edit="route.meta.permission.update" @del="deleteData(dataEdit)" @edit="redirectEdit(dataEdit)" />
  </OverlayPanel>
  <Sidebar class="type-bus-sidebar" v-model:visible="sliderActive" :showCloseIcon="false" position="right">
    <ComissionForm :dataEdit="dataEdit" @reloadData="getAllData" @closeSidebar="closeSidebar()" />
  </Sidebar>
</section>
</template>

<script src="@/composables/master/transactions/comission/comissionComposable.ts"></script>
<style lang="scss" scoped>
.p-inputgroup-addon {
  background: transparent;
  border-left: none;
}
</style>
